/* App.css */
.App {
  text-align: center;
  background-color: #1a1a1a; /* Lighter dark background */
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.App-header {
  background-color: #2c2c2c; /* Lighter gray for the header */
  height: auto; /* Header height based on content */
  padding: 20px; /* Padding for spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 1vmin); /* Responsive font size */
  color: #ffffff; /* White text for contrast */
  border-bottom: 3px solid #bb86fc; /* Light purple for visual separation */
  margin-bottom: 20px; /* Margin below header */
}

h1 {
  color: #ffffff; /* White for the welcome header */
  margin: 10px 0; /* Margin for spacing */
}

form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #2c2c2c; /* Lighter background for form */
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25); /* Softer shadow */
}

/* src/formComponents/FormFields.css */
.form-fields {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 5px; /* Space between each label and input */
  margin-top: 10px ; /* Margin around the entire form */
  margin-right: 30px ; /* Margin around the entire form */
  margin-left: 30px ;
}

label {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  font-size: 16px; /* Increase font size for better readability */
  color: white; /* Set text color to white for dark theme */
}

input {
  padding: 10px; /* Add padding inside input fields */
  border: 1px solid #61dafb; /* Light blue border for input fields */
  border-radius: 5px; /* Rounded corners */
  background-color: #3c3f44; /* Darker background for inputs */
  color: white; /* White text color for input fields */
}

input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #61dafb; /* Change border color on focus */
}


input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 12px; /* Increased padding for comfort */
  margin-bottom: 20px; /* Increased margin for spacing */
  border: 1px solid #444; /* Darker gray border */
  border-radius: 5px;
  font-size: 16px;
  background-color: #3b3b3b; /* Darker background for inputs */
  color: #ffffff; /* White text for inputs */
  transition: border-color 0.3s ease; /* Transition effect */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #bb86fc; /* Light purple on focus */
  outline: none; /* Remove default outline */
}

textarea {
  resize: vertical;
  height: 100px; /* Fixed height for textarea */
}

button[type="submit"] {
  background-color: #bb86fc; /* Light purple button */
  color: white; /* White text for buttons */
  padding: 12px 20px; /* Increased padding */
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s; /* Added transform effect */
  margin-top: 15px; /* Margin for spacing */
}

button[type="submit"]:hover {
  background-color: #9b6bca; /* Darker purple on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

button[type="button"] {
  background-color: #555; /* Darker gray for secondary button */
  color: white; /* White text for buttons */
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s; /* Added transform effect */
  margin: 10px 0; /* Margin for spacing */
}

button[type="button"]:hover {
  background-color: #444; /* Darker gray on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

hr {
  border: 1px solid #333; /* Darker separator line */
  margin-top: 10px ; /* Margin around the entire form */
  margin-right: 30px ; /* Margin around the entire form */
  margin-left: 30px ;
}

/* Product Form Specific Styling */
.product-section {
  background-color: #3b3b3b; /* Slightly lighter gray for product sections */
  padding: 15px;
  border: 1px solid #444; /* Medium gray border */
  margin-bottom: 20px; /* Margin below each product section */
  border-radius: 5px;
}

.product-section h3 {
  margin-bottom: 10px;
  color: #bb86fc; /* Light purple for product headers to make them stand out */
}

.product-heading {
  color: white; /* White text color */
  background-color: #3c3f44; /* Dark background color */
  padding: 10px 20px; /* Padding around the heading */
  border-radius: 5px; /* Rounded corners */
  border: 2px solid #61dafb; /* Light blue border */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Space below the heading */
}

/* Responsive Design */
@media (max-width: 768px) {
  form {
    padding: 15px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 14px;
  }

  button[type="submit"], button[type="button"] {
    font-size: 16px;
    padding: 10px 14px; /* Adjusted padding for smaller screens */
  }
}
/* Popup styles */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup button {
  margin-top: 10px;
  padding: 10px;
  background-color: #61dafb;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup.success {
  background-color: #4caf50; /* Green for success */
}

.popup.failure {
  background-color: #f44336; /* Red for failure */
}

.popup button:hover {
  background-color: #21a1f1;
}

.csv-upload-container {
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between items */
  margin-bottom: 20px; /* Space below the container */
}

.csv-upload-container label {
  margin: 10px; /* Remove default margin */
  display: flex; /* Make label a flex container */
  flex-direction: column; /* Stack the label and input */

}

.csv-upload-container input[type="file"] {
  padding: 5px; /* Add some padding */
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Round corners */
  height: 20px; /* Set a fixed height to align with button */
}

.csv-upload-container button {
  padding: 6px 10px; /* Add padding for button */
  background-color: #213c58; /* Button background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Round corners */
  cursor: pointer; /* Change cursor on hover */
  height: 50px; /* Set a fixed height to align with input */
}

.csv-upload-container button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}


